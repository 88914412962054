import React from 'react'
import { Link } from 'react-router-dom'
import handleScrollToTop from '../../Helpers/Helpers'
const Footer = () => {
    
    return (
        <section className="as_footer_wrapper as_padderTop80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="as_know_sign_wrapper as_padderBottom50 as_padderTop100">
                            <div className="row">
                                <div className="col-lg-4">
                                    <h1 className="as_heading as_heading_center">
                                        Zodiac Sign Finder
                                    </h1>
                                </div>
                                <div className="col-lg-8">
                                    <div className="as_sign_form text-left">
                                        <ul>
                                            <li className="as_form_box">
                                                <div className="as_input_feild as_select_box">
                                                    <select className="form-control" data-placeholder="Date">
                                                        <option value="1">
                                                            1
                                                        </option>
                                                        <option value="2">
                                                            2
                                                        </option>
                                                        <option value="3">
                                                            3
                                                        </option>
                                                        <option value="4">
                                                            4
                                                        </option>
                                                        <option value="5">
                                                            5
                                                        </option>
                                                    </select>
                                                </div>
                                            </li>
                                            <li className="as_form_box">
                                                <div className="as_input_feild as_select_box">
                                                    <select className="form-control" data-placeholder="Month">
                                                        <option value="1">
                                                            1
                                                        </option>
                                                        <option value="2">
                                                            2
                                                        </option>
                                                        <option value="3">
                                                            3
                                                        </option>
                                                        <option value="4">
                                                            4
                                                        </option>
                                                    </select>
                                                </div>
                                            </li>
                                            <li className="as_form_box">
                                                <div className="as_input_feild as_select_box">
                                                    <select className="form-control" data-placeholder="Year">
                                                        <option value="1994">
                                                            1994
                                                        </option>
                                                        <option value="1995">
                                                            1995
                                                        </option>
                                                        <option value="1996">
                                                            1996
                                                        </option>
                                                        <option value="1997">
                                                            1997
                                                        </option>
                                                        <option value="1998">
                                                            1998
                                                        </option>
                                                    </select>
                                                </div>
                                            </li>
                                            <li className="as_form_box">
                                                <a className="as_btn" href="">
                                                    Submit
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="as_footer_inner as_padderTop10 as_padderBottom40">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="as_footer_widget">
                                        <div className="as_footer_logo">
                                            <a href="index.html">
                                                <img alt="" src="assets/images/logo1.svg" />
                                            </a>
                                        </div>
                                        <p>
                                            There are many variations of this passages of Lorem Ipsum.
                                        </p>
                                        <ul className="as_contact_list">
                                            <li>
                                                <img alt="" src="assets/images/svg/map.svg" />
                                                <p>
                                                    Kolkata, New Town, 700135
                                                </p>
                                            </li>
                                            <li>
                                                <img alt="" src="assets/images/svg/phone.svg" />
                                                <p>
                                                    <a href="tel:+917407602125">
                                                        +91 7407602125
                                                    </a>
                                                </p>
                                            </li>
                                            <li>
                                                <img alt="" src="assets/images/svg/mail.svg" />
                                                <p>
                                                    <a href="mailto:support@astrologertest.com">
                                                        support@astrologertest.com
                                                    </a>
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="as_footer_widget">
                                        <h3 className="as_footer_heading">
                                            Quick Links
                                        </h3>
                                        <ul>
                                            <li>
                                                <Link to="/aboutus" onClick={handleScrollToTop}>
                                                    About Us
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/products" onClick={handleScrollToTop}>
                                                    Shop
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/blogs" onClick={handleScrollToTop}>
                                                    Blog
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/contactus" onClick={handleScrollToTop}>
                                                    Contact Us
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="as_footer_widget">
                                        <h3 className="as_footer_heading">
                                            Horoscope Forecasts
                                        </h3>
                                        <ul>
                                            <li>
                                                <a href="service_single.html">
                                                    My Daily Horoscope
                                                </a>
                                            </li>
                                            <li>
                                                <a href="service_single.html">
                                                    My Weekly Horoscope
                                                </a>
                                            </li>
                                            <li>
                                                <a href="service_single.html">
                                                    My Monthly Horoscope
                                                </a>
                                            </li>
                                            <li>
                                                <a href="service_single.html">
                                                    My Love Horoscope
                                                </a>
                                            </li>
                                            <li>
                                                <a href="service_single.html">
                                                    My Career Horoscop
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="as_footer_widget">
                                        <h3 className="as_footer_heading">
                                            Our Newsletter
                                        </h3>
                                        <p>
                                            Lorem ipsum dolor amet, consectetur adipiscing elit,sed eiusmod tempor.
                                        </p>
                                        <div className="as_newsletter_wrapper">
                                            <div className="as_newsletter_box">
                                                <input className="form-control" id="" name="" placeholder="Email..." type="text" />
                                                <a className="as_btn" href="">
                                                    <img alt="" src="assets/images/svg/plane.svg" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="as_login_data">
                                            <label>
                                                I agree that my submitted data is

                                                being collected and stored.
                                                <input name="as_remember_me" type="checkbox" value="" />
                                                <span className="checkmark">
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="as_copyright_wrapper text-center">
                            <p>
                                Copyright © 2022 Astrology. All Right Reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Footer
