import React from 'react'

const Breadcrum = ({ title , url = '/' }) => {

    return (
        <>
            <section className="as_breadcrum_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h1>
                                {title}
                            </h1>
                            <ul className="breadcrumb">
                                <li>
                                    <a href={url}>
                                        Home
                                    </a>
                                </li>
                                <li>
                                    {title}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Breadcrum
