import React from 'react'
import { Link } from 'react-router-dom'

const ProductCard = ({ product, column = 4}) => {
    return (
        <>
            <div className={`col-lg-${column} col-md-6 col-sm-6 col-xs-12`} key={product._id}>
                <div className="as_product_box">
                    <div className="as_product_img">
                        <img
                            className="img-responsive"
                            alt="Product Image"
                            src={`${product.full_image}`}
                            style={{ height: "220px" }}
                        />
                        <span className="as_new_tag">new</span>
                        <ul>
                            <li>
                                <a href="#">
                                    <img alt="" src="/assets/images/svg/cart.svg" />
                                    <span>Add To Cart</span>
                                </a>
                            </li>
                            <li>
                                <Link to={"/products/view/" + product._id}>
                                    <img alt="" src="/assets/images/svg/view.svg" />
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="as_product_detail">
                        <h4 className="as_subheading">{product.title}</h4>
                        <span className="as_price">
                            ₹ {product.price.$numberDecimal}
                            <del>₹ {product.del_price.$numberDecimal}</del>
                        </span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductCard
