import React, { useState } from 'react'

const CartComponent = () => {
    const [isCartOpen, setIsCartOpen] = useState(false)

    const toggleCart = () => {
        setIsCartOpen(!isCartOpen)
    }

    return (
        <div className={`as_cart ${isCartOpen ? 'cart_open' : ''}`}>
            <div className="as_cart_wrapper" onClick={toggleCart}>
                <span>
                    <img alt="" src="/assets/images/svg/cart.svg" />
                    <span className="as_cartnumber">02</span>
                </span>
            </div>
            {isCartOpen && (
                <div className="as_cart_box">
                    <div className="as_cart_list">
                        <ul>
                            <li>
                                <div className="as_cart_img">
                                    <img className="img-responsive" src="/assets/images/p1.jpg" alt="Product 1" />
                                </div>
                                <div className="as_cart_info">
                                    <a href="#">
                                        Yellow Sapphire
                                    </a>
                                    <p>1 X $20.00</p>
                                    <a className="as_cart_remove" href="#">
                                        <i className="fa fa-trash"></i>
                                    </a>
                                </div>
                            </li>
                            <li>
                                <div className="as_cart_img">
                                    <img className="img-responsive" src="/assets/images/p2.jpg" alt="Product 2" />
                                </div>
                                <div className="as_cart_info">
                                    <a href="#">
                                        Yantra
                                    </a>
                                    <p>1 X $10.00</p>
                                    <a className="as_cart_remove" href="#">
                                        <i className="fa fa-trash"></i>
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="as_cart_total">
                        <p>
                            Total
                            <span>$30.00</span>
                        </p>
                    </div>
                    <div className="as_cart_btn">
                        <button className="as_btn" type="button">
                            View Cart
                        </button>
                        <button className="as_btn" type="button">
                            Checkout
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default CartComponent
