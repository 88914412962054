import React from 'react'
import { Link } from 'react-router-dom'
import CartComponent from '../../components/CartComponent'
import Menu from '../../components/Menu'

const Header = () => {
    return (
        <section className="as_header_wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-4 col-xs-6">
                        <div className="as_logo">
                            <a href="/">
                                <img alt="" src="/assets/images/logo.svg" />
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-8 col-xs-6">
                        <div className="as_right_info">
                            <div className="as_menu_wrapper">
                                <span className="as_toggle">
                                    <img alt="" src="/assets/images/svg/menu.svg" />
                                </span>
                                <Menu />
                            </div>

                            <CartComponent />

                            <div className="as_user">
                                    <a href="/login">
                                        <img alt="" src="/assets/images/profile.jpg" />
                                        <span className="as_add_user">
                                            +
                                        </span>
                                    </a>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Header
