import { Link, useNavigate, useParams } from "react-router-dom"
import PopularProducts from "../../components/PopularProducts"
import Helpers from "../../Helpers/Helpers"
import { useEffect, useState } from "react"
import Notification from '../../Helpers/Notification'
import moment from "moment"
import Faq from "../../components/Faq"
import Breadcrum from "../../components/Breadcrum"


const BlogViewComponent = ({ title }) => {

    const { id } = useParams() // Get product ID from the URL
    const helpers = Helpers()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [blog, setBlog] = useState(null) // Store the product data

    useEffect(() => {
        // Fetch product details for pre-populating the form
        const fetchData = async () => {
            try {
                const response = await helpers.httpRequest(`/posts/${id}?populate=true`, 'GET')
                if (response.status === 'success') {
                    setBlog(response)
                } else {
                    Notification('error', 'Error fetching product data')
                    navigate('/notfound')
                }
            } catch (error) {
                console.error('Error fetching:', error)
                Notification('error', 'Error fetching data')
            }
        }

        // Show loader until both product and categories are fetched
        Promise.all([fetchData()]).finally(() => setLoading(false))
    }, [id])
    return (
        <>

            <Breadcrum title={title} />

            <section className="as_blog_wrapper as_padderBottom90 as_padderTop80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                            <div className="as_shop_sidebar">

                                <div className="as_widget as_category_widget">
                                    <h3 className="as_widget_title">
                                        Categories
                                    </h3>
                                    <ul>
                                        <li>
                                            <a href="">
                                                Kundali Dosha
                                            </a>
                                        </li>
                                        <li>
                                            <a href="">
                                                Face Reading
                                            </a>
                                        </li>
                                        <li>
                                            <a href="">
                                                Daily Horoscope
                                            </a>
                                        </li>
                                        <li>
                                            <a href="">
                                                Personal Consultation
                                            </a>
                                        </li>
                                        <li>
                                            <a href="">
                                                gem &amp; Stone
                                            </a>
                                        </li>
                                        <li>
                                            <a href="">
                                                Manglik Dosha
                                            </a>
                                        </li>
                                        <li>
                                            <a href="">
                                                Vastu Shastra
                                            </a>
                                        </li>
                                        <li>
                                            <a href="">
                                                planets
                                            </a>
                                        </li>
                                        <li>
                                            <a href="">
                                                Numerology
                                            </a>
                                        </li>
                                        <li>
                                            <a href="">
                                                tarot cards
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                {/* 
                                <div className="as_widget as_workinghours_widget">
                                    <h3 className="as_widget_title">
                                        Working Hours
                                    </h3>
                                    <ul>
                                        <li>
                                            <a href="">
                                                <span>
                                                    Monday
                                                </span>
                                                <span>
                                                    09:00 - 12:00
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="">
                                                <span>
                                                    Tuesday
                                                </span>
                                                <span>
                                                    09:00 - 12:00
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="">
                                                <span>
                                                    Wednesday
                                                </span>
                                                <span>
                                                    09:00 - 12:00
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="">
                                                <span>
                                                    Thursday
                                                </span>
                                                <span>
                                                    09:00 - 12:00
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="">
                                                <span>
                                                    Friday
                                                </span>
                                                <span>
                                                    09:00 - 12:00
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="">
                                                <span>
                                                    Saturday
                                                </span>
                                                <span>
                                                    09:00 - 12:00
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="">
                                                <span>
                                                    Sunday
                                                </span>
                                                <span>
                                                    09:00 - 12:00
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div> */}

                                <div className="as_widget as_product_widget as_post_widget">
                                    <h3 className="as_widget_title">
                                        Recent Posts
                                    </h3>
                                    <ul>
                                        <li className="as_product">
                                            <a href="shop_single.html">
                                                <span className="as_productimg">
                                                    <img alt="" src="/assets/images/post1.jpg" />
                                                </span>
                                                <span className="as_product_detail">
                                                    <span>
                                                        <img alt="" src="/assets/images/svg/calendar.svg" />
                                                        14/01/2022
                                                    </span>
                                                    <span>
                                                        Lorem ipsum dolor sit amet, consectetur.
                                                    </span>
                                                </span>
                                            </a>
                                        </li>
                                        <li className="as_product">
                                            <a href="shop_single.html">
                                                <span className="as_productimg">
                                                    <img alt="" src="/assets/images/post1.jpg" />
                                                </span>
                                                <span className="as_product_detail">
                                                    <span>
                                                        <img alt="" src="/assets/images/svg/calendar.svg" />
                                                        14/01/2022
                                                    </span>
                                                    <span>
                                                        Lorem ipsum dolor sit amet, consectetur.
                                                    </span>
                                                </span>
                                            </a>
                                        </li>
                                        <li className="as_product">
                                            <a href="shop_single.html">
                                                <span className="as_productimg">
                                                    <img alt="" src="/assets/images/post1.jpg" />
                                                </span>
                                                <span className="as_product_detail">
                                                    <span>
                                                        <img alt="" src="/assets/images/svg/calendar.svg" />
                                                        14/01/2022
                                                    </span>
                                                    <span>
                                                        Lorem ipsum dolor sit amet, consectetur.
                                                    </span>
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                <Faq />

                            </div>
                        </div>
                        <div className="col-lg-9 col-md-8 col-sm-12 col-xs-12">
                            <div className="as_blog_box">
                                <div className="as_blog_img">
                                    <a href="blog_detail.html">
                                        <img alt="" className="img-responsive" src={blog?.full_image} />
                                    </a>
                                    <span className="as_btn">
                                        {moment(blog?.created_at).format("MMM DD, YYYY")}
                                    </span>
                                </div>
                                <div className="as_blog_detail">
                                    <ul>
                                        <li>
                                            <Link href="">
                                                <img alt="" src="/assets/images/svg/user2.svg" />
                                                By - Admin
                                            </Link>
                                        </li>
                                    </ul>
                                    <h4 className="as_subheading">
                                        {blog?.title}
                                    </h4>
                                    <p className="as_font14 as_margin0 as_padderBottom10">
                                        {blog?.description}
                                    </p>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default BlogViewComponent
