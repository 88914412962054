import { useEffect, useState, useRef } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { io } from "socket.io-client"
import Helpers from "../../Helpers/Helpers"
import Breadcrum from "../../components/Breadcrum"
import { useProfile } from "../../context/ProfileContext"
import FullPageLoader from "../../components/FullPageLoader"
import Swal from 'sweetalert2'

const ChatComponent = ({ title }) => {
    const { id } = useParams()
    const helpers = Helpers()
    const userProfile = useProfile()
    const navigate = useNavigate()
    const { search } = useLocation()

    const [messages, setMessages] = useState([]) // Start with an empty message array
    const [newMessage, setNewMessage] = useState("")
    const messagesEndRef = useRef(null)
    const socket = useRef(null) // Use a ref to store the socket instance
    const [isTyping, setIsTyping] = useState(false)
    const [astrologer, setAstrologer] = useState({})
    const [loading, setLoading] = useState(false)
    const [initiate, setInitiate] = useState(false)
    const [isBusy, setIsBusy] = useState(true)
    const [chatSession, setChatSession] = useState({})
    const [status, setStatus] = useState('pending')

    // GET ASTROLOGER INFO
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                const response = await helpers.httpRequest(`/chat/${id}`, 'GET')
                // console.log(response);

                if (response.status === 'success') {
                    setAstrologer(response.data)
                    setChatSession(response.data)
                    setStatus(response.data.status)
                    // setIsBusy(response.data.isBusy && response.data.isBusy.userId !== userProfile.userInfo.id);

                } else {
                    navigate('/astrologer')
                }
            } catch (error) {
                console.error('Error fetching Astrologers:', error)
            } finally {
                setLoading(false)
            }
        }

        fetchData()
    }, [])

    // Initialize Socket.IO client
    useEffect(() => {
        socket.current = io(process.env.REACT_APP_API_URL, {
            auth: {
                token: helpers.getStorage('accessToken') // Retrieve token from local storage or another source
            }
        })
        // Listen for incoming messages
        socket.current.on("sendMessage", (data) => {
            console.log(data);

            const message = {
                content: data.content,
                sender: "Assistant",
                timestamp: data.timestamp
            }
            setMessages((prev) => [...prev, message])
        })

        socket.current.on("sendTyping", (data) => {
            setIsTyping(data.message)
        })

        socket.current.on("astrologerAcceptChat", (data) => {
            setStatus(data.chat_id == id && 'accepted')

        })

        socket.current.on("error", (data) => {
            if (data && data?.msg == 'Astrologer is busy!') {
                setIsBusy(true)
            }
            console.log(data);

        })

        // Cleanup on unmount
        return () => {
            socket.current.disconnect()
        }
    }, [])

    useEffect(() => {
        if (messagesEndRef && messagesEndRef.current) {
            const element = messagesEndRef.current
            element.scroll({
                top: element.scrollHeight,
                left: 0,
                behavior: "smooth"
            })
        }
    }, [messages, isTyping])

    const initiateChat = () => {
        setInitiate(true)
        socket.current.emit("initiateChat", { chatSession, user: { name: userProfile.userInfo.name, id: userProfile.userInfo.id }, astrologer: chatSession.astrologerDetails })
    }

    useEffect(() => {
        setTimeout(()=>{
            initiateChat()
        }, 3000)
    })

    const handleSendMessage = async () => {
        if (!newMessage.trim()) return

        const message = {
            content: newMessage,
            sender: 'You',
            send_to: chatSession.astrologerId,
            timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
        }

        // Add the new message to the chat immediately
        setMessages((prev) => [...prev, message])
        setNewMessage("")
        handleTyping('')
        setIsTyping(false)

        try {
            // Emit the message to the server
            socket.current.emit("receiveMessage", { message: message })
        } catch (error) {
            console.error('Error sending message:', error)
        }
    }

    const handleTyping = async (message) => {
        try {
            socket.current.emit("receiveTyping", { user_id: userProfile.userInfo.id, send_to: chatSession.astrologerId, message: message.length > 0 })
        } catch (error) {
            console.error('Error sending typing:', error)
        }
    }

    const handleEndSession = async () => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to close the chat!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, close it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await helpers.httpRequest(`/chat/update/${id}`, 'POST', {status : 'completed'});
                    if(response.status == 'success'){
                        setStatus('completed')
                    }
                    
                    socket.current.emit("userClosedChat", { user_id: userProfile.userInfo.id, send_to: chatSession.astrologerId, chat_id : chatSession._id })

                } catch (error) {
                    console.error("Error closing chat:", error);
                    Swal.fire({
                        title: "Error!",
                        text: "Failed to close the chat.",
                        icon: "error"
                    });
                }
            }
        });
    };


    return (
        <>
            {loading && <FullPageLoader />}
            <Breadcrum title={title} url='/' />
            <section className="chat_wrapper">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            {
                                status === 'completed' ? (
                                    <h1>Chat has been ended!</h1>
                                ) : (
                                    <div className="chat_area">
                                        <div className="chat_header justify-content-between">
                                            <div>
                                                <img src={chatSession?.astrologerDetails?.full_profile_pic} alt={chatSession?.astrologerDetails?.name} className="chat_partner_image" />
                                                <b className="chat_partner_name">{chatSession?.astrologerDetails?.name}</b>
                                            </div>
                                            <div className="chat_header_right">
                                                {
                                                    status == 'accepted' && <button className="btn btn-danger" onClick={() => handleEndSession()}>Close Chat</button>

                                                }
                                            </div>

                                        </div>

                                        {/* Messages container */}
                                        <div className="messages" ref={messagesEndRef}>
                                            {messages.map((msg, index) => (
                                                <div key={index} className={`message ${msg.sender === 'You' ? 'sent' : 'received'}`}>
                                                    <span className="content">{msg.content}</span>
                                                    <span className="timestamp">{msg.timestamp}</span>
                                                </div>
                                            ))}
                                            {/* Typing animation */}
                                            {isTyping && (
                                                <div className="typing-indicator">
                                                    <span className="dot"></span>
                                                    <span className="dot"></span>
                                                    <span className="dot"></span>
                                                </div>
                                            )}
                                        </div>
                                        {
                                            status === 'pending' ? (
                                                <h3>Chat will start once the Astrologer accept, wait...</h3>
                                            ) : (
                                                <div className="message_input">
                                                    <input
                                                        type="text"
                                                        placeholder="Type a message..."
                                                        value={newMessage}
                                                        onChange={(e) => setNewMessage(e.target.value)}
                                                        onKeyUp={(e) => {
                                                            handleTyping(e.target.value);
                                                            if (e.key === 'Enter') {
                                                                if (!initiate) {
                                                                    // initiateChat();
                                                                    handleSendMessage();
                                                                } else {
                                                                    handleSendMessage();
                                                                }
                                                            }
                                                        }}
                                                    />
                                                    <button onClick={() => {
                                                        if (!initiate) {
                                                            // initiateChat();
                                                            handleSendMessage();
                                                        } else {
                                                            handleSendMessage();
                                                        }
                                                    }}>
                                                        Send
                                                    </button>
                                                </div>
                                            )
                                        }



                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </section>

            <style jsx='true'>{`
                .chat_wrapper {
                    display: flex;
                    justify-content: center;
                    padding: 2em 0;
                    background-color: #f3f4f6;
                    min-height: 100vh;
                }
                .chat_area {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    max-width: 100%;
                    height: 70vh; /* Fixed height for chat area */
                    background-color: #fff;
                    border-radius: 10px;
                    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
                    overflow: hidden;
                }
                .chat_header {
                    display: flex;
                    align-items: center;
                    padding: 1em;
                    background-color: #007bff;
                    color: #fff;
                }
                .chat_partner_image {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    margin-right: 0.8em;
                }
                .chat_partner_name {
                    font-size: 1.2rem;
                    font-weight: bold;
                }
                .messages {
                    flex-grow: 1;
                    padding: 1em;
                    overflow-y: auto; /* Only scroll messages */
                    display: flex;
                    flex-direction: column;
                    gap: 0.8em;
                    background-color: #fafafa;
                }
                .message {
                    max-width: 75%;
                    padding: 0.8em;
                    border-radius: 20px;
                    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
                    position: relative;
                }
                .sent {
                    background-color: #DCF8C6;
                    align-self: flex-end;
                    color: #333;
                    border-bottom-right-radius: 4px;
                }
                .received {
                    background-color: #f0f0f0;
                    align-self: flex-start;
                    color: #555;
                    border-bottom-left-radius: 4px;
                }
                .content {
                    font-size: 0.95rem;
                    margin-bottom: 4px;
                    line-height: 1.4;
                }
                .timestamp {
                    font-size: 0.75rem;
                    color: #888;
                    text-align: right;
                    display: block;
                    margin-top: 5px;
                }
                .message_input {
                    display: flex;
                    align-items: center;
                    padding: 0.8em;
                    background-color: #fafafa;
                    border-top: 1px solid #ddd;
                }
                .message_input input {
                    flex-grow: 1;
                    padding: 0.8em;
                    border-radius: 20px;
                    border: 1px solid #ddd;
                    outline: none;
                    font-size: 0.95rem;
                    transition: border-color 0.3s;
                }
                .message_input input:focus {
                    border-color: #007bff;
                }
                .message_input button {
                    margin-left: 0.5em;
                    padding: 0.6em 1em;
                    background-color: #007bff;
                    color: #fff;
                    border: none;
                    border-radius: 20px;
                    cursor: pointer;
                    font-size: 0.9rem;
                    transition: background-color 0.3s;
                }
                .message_input button:hover {
                    background-color: #0056b3;
                }
                .typing-indicator {
                    display: flex;
                    align-items: center;
                    margin-top: 5px;
                    background: #5a4f4f;
                    width: 60px;
                    padding: 10px;
                    border-radius: 100px;
                }
                .dot {
                    width: 8px;
                    height: 8px;
                    margin: 0 2px;
                    border-radius: 50%;
                    background-color: #ffffff;
                    animation: typing 0.6s infinite alternate;
                }
                .dot:nth-child(2) {
                    animation-delay: 0.2s;
                }
                .dot:nth-child(3) {
                    animation-delay: 0.4s;
                }
                @keyframes typing {
                    0% {
                        transform: translateY(0);
                    }
                    100% {
                        transform: translateY(-5px);
                    }
                }
            `}</style>
        </>
    )
}

export default ChatComponent
