import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Login from '../screens/Login'
import NotFound from '../screens/Notfound'
import { AuthProvider } from '../context/AuthContext'
import { NetworkProvider } from '../context/NetworkProvider'
import { ProfileProvider } from '../context/ProfileContext'
import NotProtectedRoute from '../components/NotProtectedRoute'
import ProtectedRoute from '../components/ProtectedRoute'
import Dashboard from '../screens/Dashboard'
import ShopSingle from '../screens/ShopSingle'
import Shop from '../screens/Shop'
import Astrologer from '../screens/Astrologer'
import Blog from '../screens/Blog'
import BlogView from '../screens/BlogView'
import Aboutus from '../screens/Aboutus'
import Contactus from '../screens/Contactus'
import ScrollToTop from '../Helpers/ScrollToTop'
import Chat from '../screens/Chat'

const RoutesComponent = () => {
    return (
        <ProfileProvider>
            <NetworkProvider>
                <AuthProvider>
                    <Router basename="/">
                        <ScrollToTop />
                        <Routes>
                            <Route path="/login" element={<NotProtectedRoute><Login title="Login" /></NotProtectedRoute>} />
                            <Route path="/chat/:id" element={<ProtectedRoute><Chat title="Chat" /></ProtectedRoute>} />
                            <Route exact path="/" element={<Dashboard title="Dashboard" />} />
                            <Route path="/products" element={<Shop title="Shop" />} />
                            <Route path="/products/view/:id" element={<ShopSingle title="Product View" />} />
                            <Route path="/blogs" element={<Blog title="Blogs" />} />
                            <Route path="/blogs/view/:id" element={<BlogView title="Blog View" />} />
                            <Route path="/aboutus" element={<Aboutus title="About US" />} />
                            <Route path="/contactus" element={<Contactus title="Contact US" />} />
                            <Route path="/notfound" element={<NotFound title="Not Found" />} />
                            <Route path="/astrologer" element={<Astrologer title="Astrologer" />} />
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </Router>
                </AuthProvider>
            </NetworkProvider>
        </ProfileProvider>
    )
}

export default RoutesComponent
